// export const contractAddress = "0xf415bd9958C890B11f9908C5b3FE1Cca86c53c68"; //rinkeby测试中的合约
export const contractAddress = "0x30a64ee98e78ae292Cb16E82C8C72DdE2f0cB82F"; //正式网代币

export const contractAbi = [
  {
    constant: false,
    inputs: [
      { name: "orderID", type: "string" },
      { name: "amount", type: "uint256" },
      { name: "coinID", type: "uint256" },
    ],
    name: "transferOut",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "coinID", type: "uint256" },
      { name: "_tokenAddress", type: "address" },
    ],
    name: "setTradingCoins",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "stp", type: "uint256" },
      { name: "oid", type: "string" },
      { name: "ads", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    name: "SetOrders",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "orderID", type: "string" }],
    name: "transferIn01",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "amount", type: "uint256" },
      { name: "orderID", type: "string" },
      { name: "coinID", type: "uint256" },
    ],
    name: "transferAdd",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "orderID", type: "string" }],
    name: "getInfo_arb",
    outputs: [
      { name: "", type: "address" },
      { name: "", type: "uint256" },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_tokenAddress", type: "address" },
      { name: "_num", type: "uint256" },
    ],
    name: "withdrawCoin",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "orderID", type: "string" },
      { name: "amount", type: "uint256" },
      { name: "coinID", type: "uint256" },
    ],
    name: "transferOutfor1",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "orderID", type: "string" }],
    name: "getInfo_orderOut",
    outputs: [
      { name: "", type: "address" },
      { name: "", type: "uint256" },
      { name: "", type: "address" },
      { name: "", type: "uint256" },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "amount", type: "uint256" },
      { name: "_token", type: "address" },
      { name: "ads", type: "address" },
    ],
    name: "transferCoin",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "amount", type: "uint256" },
      { name: "orderID", type: "string" },
      { name: "_ads", type: "address" },
      { name: "coinID", type: "uint256" },
    ],
    name: "arbMsg",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_ads", type: "address[]" },
      { name: "_amount", type: "uint256[]" },
      { name: "uid", type: "string" },
    ],
    name: "airMsg",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "ads", type: "address" }],
    name: "getInfo_air",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "orderID", type: "string" }],
    name: "getInfo_Out",
    outputs: [
      { name: "", type: "address" },
      { name: "", type: "uint256" },
      { name: "", type: "address" },
      { name: "", type: "uint256" },
      { name: "", type: "string" },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "orderID", type: "string" },
      { name: "amount", type: "uint256" },
    ],
    name: "arbMsgOut",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "orderID", type: "string" },
      { name: "amount", type: "uint256" },
      { name: "coinID", type: "uint256" },
    ],
    name: "transferOutfor",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "orderID", type: "string" }],
    name: "getInfo_order",
    outputs: [
      { name: "", type: "address" },
      { name: "", type: "uint256" },
      { name: "", type: "uint256" },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "amount", type: "uint256" },
      { name: "orderID", type: "string" },
      { name: "coinID", type: "uint256" },
    ],
    name: "transferIn",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_ads", type: "address" }],
    name: "airMsgOut",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "coinID", type: "uint256" }],
    name: "getInfo_coins",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "amount", type: "uint256" },
      { name: "orderID", type: "string" },
      { name: "orderads", type: "address" },
      { name: "coinID", type: "uint256" },
    ],
    name: "transferIn1",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "amount", type: "uint256" },
      { name: "orderID", type: "string" },
      { name: "orderads", type: "address" },
      { name: "oid", type: "string" },
      { name: "coinID", type: "uint256" },
    ],
    name: "transferIn0",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "amount", type: "uint256" },
      { name: "orderID", type: "string" },
      { name: "oid", type: "string" },
      { name: "coinID", type: "uint256" },
      { name: "orderads", type: "address" },
    ],
    name: "transferIn00",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "ads", type: "address" },
      { name: "amount", type: "uint256" },
      { name: "_tokenAddress", type: "address" },
    ],
    name: "transferToken",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_recipients", type: "address[]" },
      { name: "_values", type: "uint256[]" },
      { name: "uid", type: "string" },
      { name: "_tokenAddress", type: "address" },
    ],
    name: "AirTransfer",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "ads", type: "address" },
      { indexed: false, name: "amount", type: "uint256" },
      { indexed: false, name: "orderID", type: "string" },
    ],
    name: "_transferIn",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "ads", type: "address" },
      { indexed: false, name: "amount", type: "uint256" },
      { indexed: false, name: "orderID", type: "string" },
    ],
    name: "_transferAdd",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "ads", type: "address" },
      { indexed: false, name: "amount", type: "uint256" },
      { indexed: false, name: "orderID", type: "string" },
    ],
    name: "_transferOut",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "ads", type: "address" },
      { indexed: false, name: "amount", type: "uint256" },
      { indexed: false, name: "orderID", type: "string" },
    ],
    name: "_transfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: "orderID", type: "string" }],
    name: "_cancelOrder",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "ads", type: "address" },
      { indexed: false, name: "amount", type: "uint256" },
      { indexed: false, name: "orderID", type: "string" },
    ],
    name: "_transferOutfor",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "ads", type: "address" },
      { indexed: false, name: "amount", type: "uint256" },
      { indexed: false, name: "orderID", type: "string" },
    ],
    name: "_transferIn1",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "ads", type: "address" },
      { indexed: false, name: "amount", type: "uint256" },
      { indexed: false, name: "orderID", type: "string" },
    ],
    name: "_transferOutfor1",
    type: "event",
  },
];
// export const contractAddress_BNB = "0x58ae9E73d8494c7ca3D005C2112C74e11d622FDc";
// export const contractAbi_BNB = [
//   {
//     constant: false,
//     inputs: [
//       { name: "stp", type: "uint256" },
//       { name: "oid", type: "string" },
//       { name: "ads", type: "address" },
//       { name: "amount", type: "uint256" },
//     ],
//     name: "SetOrders",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [{ name: "orderID", type: "string" }],
//     name: "transferIn01",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [{ name: "orderID", type: "string" }],
//     name: "getInfo_arb",
//     outputs: [
//       { name: "", type: "address" },
//       { name: "", type: "uint256" },
//     ],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "orderID", type: "string" },
//       { name: "amount", type: "uint256" },
//     ],
//     name: "transferOutfor1",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "_tokenAddress", type: "address" },
//       { name: "_num", type: "uint256" },
//     ],
//     name: "withdrawCoin",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [{ name: "orderID", type: "string" }],
//     name: "getInfo_orderOut",
//     outputs: [
//       { name: "", type: "address" },
//       { name: "", type: "uint256" },
//       { name: "", type: "address" },
//     ],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [{ name: "orderID", type: "string" }],
//     name: "transferAdd",
//     outputs: [],
//     payable: true,
//     stateMutability: "payable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "amount", type: "uint256" },
//       { name: "orderID", type: "string" },
//       { name: "orderads", type: "address" },
//       { name: "oid", type: "string" },
//     ],
//     name: "transferIn0",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [{ name: "orderID", type: "string" }],
//     name: "getInfo_Out",
//     outputs: [
//       { name: "", type: "address" },
//       { name: "", type: "uint256" },
//       { name: "", type: "address" },
//       { name: "", type: "string" },
//     ],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "orderID", type: "string" },
//       { name: "amount", type: "uint256" },
//     ],
//     name: "arbMsgOut",
//     outputs: [{ name: "", type: "bool" }],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "orderID", type: "string" },
//       { name: "amount", type: "uint256" },
//     ],
//     name: "transferOutfor",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [{ name: "orderID", type: "string" }],
//     name: "getInfo_order",
//     outputs: [
//       { name: "", type: "address" },
//       { name: "", type: "uint256" },
//     ],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "orderID", type: "string" },
//       { name: "orderads", type: "address" },
//     ],
//     name: "transferIn1",
//     outputs: [],
//     payable: true,
//     stateMutability: "payable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [{ name: "orderID", type: "string" }],
//     name: "transferIn",
//     outputs: [],
//     payable: true,
//     stateMutability: "payable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "amount", type: "uint256" },
//       { name: "orderID", type: "string" },
//       { name: "oid", type: "string" },
//       { name: "orderads", type: "address" },
//     ],
//     name: "transferIn00",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "ads", type: "address" },
//       { name: "amount", type: "uint256" },
//       { name: "_tokenAddress", type: "address" },
//     ],
//     name: "transferToken",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "amount", type: "uint256" },
//       { name: "orderID", type: "string" },
//       { name: "_ads", type: "address" },
//     ],
//     name: "arbMsg",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "orderID", type: "string" },
//       { name: "amount", type: "uint256" },
//     ],
//     name: "transferOut",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     inputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "constructor",
//   },
//   {
//     anonymous: false,
//     inputs: [{ indexed: false, name: "orderID", type: "string" }],
//     name: "_transferIn",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [{ indexed: false, name: "orderID", type: "string" }],
//     name: "_transferAdd",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       { indexed: false, name: "orderID", type: "string" },
//       { indexed: false, name: "amount", type: "uint256" },
//     ],
//     name: "_transferOut",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       { indexed: false, name: "ads", type: "address" },
//       { indexed: false, name: "amount", type: "uint256" },
//       { indexed: false, name: "orderID", type: "string" },
//     ],
//     name: "_transfer",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [{ indexed: false, name: "orderID", type: "string" }],
//     name: "_cancelOrder",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       { indexed: false, name: "ads", type: "address" },
//       { indexed: false, name: "amount", type: "uint256" },
//       { indexed: false, name: "orderID", type: "string" },
//     ],
//     name: "_transferOutfor",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       { indexed: false, name: "ads", type: "address" },
//       { indexed: false, name: "amount", type: "uint256" },
//       { indexed: false, name: "orderID", type: "string" },
//     ],
//     name: "_transferIn1",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       { indexed: false, name: "ads", type: "address" },
//       { indexed: false, name: "amount", type: "uint256" },
//       { indexed: false, name: "orderID", type: "string" },
//     ],
//     name: "_transferOutfor1",
//     type: "event",
//   },
// ];
//正式本币交易合约
export const contractAddress_BNB = "0xc783494451f808Ed69725885020388CDf53298E4";
export const contractAbi_BNB = [
  {
    constant: false,
    inputs: [
      { name: "stp", type: "uint256" },
      { name: "oid", type: "string" },
      { name: "ads", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    name: "SetOrders",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "orderID", type: "string" }],
    name: "transferIn01",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "orderID", type: "string" }],
    name: "getInfo_arb",
    outputs: [
      { name: "", type: "address" },
      { name: "", type: "uint256" },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "orderID", type: "string" },
      { name: "amount", type: "uint256" },
    ],
    name: "transferOutfor1",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_tokenAddress", type: "address" },
      { name: "_num", type: "uint256" },
    ],
    name: "withdrawCoin",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "orderID", type: "string" }],
    name: "getInfo_orderOut",
    outputs: [
      { name: "", type: "address" },
      { name: "", type: "uint256" },
      { name: "", type: "address" },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "orderID", type: "string" }],
    name: "transferAdd",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "amount", type: "uint256" },
      { name: "orderID", type: "string" },
      { name: "orderads", type: "address" },
      { name: "oid", type: "string" },
    ],
    name: "transferIn0",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "orderID", type: "string" }],
    name: "getInfo_Out",
    outputs: [
      { name: "", type: "address" },
      { name: "", type: "uint256" },
      { name: "", type: "address" },
      { name: "", type: "string" },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "orderID", type: "string" },
      { name: "amount", type: "uint256" },
    ],
    name: "arbMsgOut",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "orderID", type: "string" },
      { name: "amount", type: "uint256" },
    ],
    name: "transferOutfor",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "orderID", type: "string" }],
    name: "getInfo_order",
    outputs: [
      { name: "", type: "address" },
      { name: "", type: "uint256" },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "orderID", type: "string" },
      { name: "orderads", type: "address" },
    ],
    name: "transferIn1",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "orderID", type: "string" }],
    name: "transferIn",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "amount", type: "uint256" },
      { name: "orderID", type: "string" },
      { name: "oid", type: "string" },
      { name: "orderads", type: "address" },
    ],
    name: "transferIn00",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "ads", type: "address" },
      { name: "amount", type: "uint256" },
      { name: "_tokenAddress", type: "address" },
    ],
    name: "transferToken",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "amount", type: "uint256" },
      { name: "orderID", type: "string" },
      { name: "_ads", type: "address" },
    ],
    name: "arbMsg",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "orderID", type: "string" },
      { name: "amount", type: "uint256" },
    ],
    name: "transferOut",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: "orderID", type: "string" }],
    name: "_transferIn",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: "orderID", type: "string" }],
    name: "_transferAdd",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "orderID", type: "string" },
      { indexed: false, name: "amount", type: "uint256" },
    ],
    name: "_transferOut",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "ads", type: "address" },
      { indexed: false, name: "amount", type: "uint256" },
      { indexed: false, name: "orderID", type: "string" },
    ],
    name: "_transfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: "orderID", type: "string" }],
    name: "_cancelOrder",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "ads", type: "address" },
      { indexed: false, name: "amount", type: "uint256" },
      { indexed: false, name: "orderID", type: "string" },
    ],
    name: "_transferOutfor",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "ads", type: "address" },
      { indexed: false, name: "amount", type: "uint256" },
      { indexed: false, name: "orderID", type: "string" },
    ],
    name: "_transferIn1",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "ads", type: "address" },
      { indexed: false, name: "amount", type: "uint256" },
      { indexed: false, name: "orderID", type: "string" },
    ],
    name: "_transferOutfor1",
    type: "event",
  },
];

//rinkeby测试网中自发行的USDT
// export const contractAddress_usdt =
//   "0xa10Bc38b56675F1721e5F329d6836f40dE67CDB1";
// export const contractAbi_usdt = [
//   {
//     constant: true,
//     inputs: [],
//     name: "mintingFinished",
//     outputs: [{ name: "", type: "bool" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "name",
//     outputs: [{ name: "", type: "string" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "_spender", type: "address" },
//       { name: "_value", type: "uint256" },
//     ],
//     name: "approve",
//     outputs: [{ name: "", type: "bool" }],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "totalSupply",
//     outputs: [{ name: "", type: "uint256" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "_from", type: "address" },
//       { name: "_to", type: "address" },
//       { name: "_value", type: "uint256" },
//     ],
//     name: "transferFrom",
//     outputs: [{ name: "", type: "bool" }],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "decimals",
//     outputs: [{ name: "", type: "uint8" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [],
//     name: "unpause",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "_to", type: "address" },
//       { name: "_amount", type: "uint256" },
//     ],
//     name: "mint",
//     outputs: [{ name: "", type: "bool" }],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "paused",
//     outputs: [{ name: "", type: "bool" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "_spender", type: "address" },
//       { name: "_subtractedValue", type: "uint256" },
//     ],
//     name: "decreaseApproval",
//     outputs: [{ name: "success", type: "bool" }],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [{ name: "_owner", type: "address" }],
//     name: "balanceOf",
//     outputs: [{ name: "", type: "uint256" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [],
//     name: "pause",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "owner",
//     outputs: [{ name: "", type: "address" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "symbol",
//     outputs: [{ name: "", type: "string" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "_to", type: "address" },
//       { name: "_value", type: "uint256" },
//     ],
//     name: "transfer",
//     outputs: [{ name: "", type: "bool" }],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [],
//     name: "mintTotal",
//     outputs: [{ name: "", type: "uint256" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [
//       { name: "_spender", type: "address" },
//       { name: "_addedValue", type: "uint256" },
//     ],
//     name: "increaseApproval",
//     outputs: [{ name: "success", type: "bool" }],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     constant: true,
//     inputs: [
//       { name: "_owner", type: "address" },
//       { name: "_spender", type: "address" },
//     ],
//     name: "allowance",
//     outputs: [{ name: "", type: "uint256" }],
//     payable: false,
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     constant: false,
//     inputs: [{ name: "newOwner", type: "address" }],
//     name: "transferOwnership",
//     outputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     inputs: [],
//     payable: false,
//     stateMutability: "nonpayable",
//     type: "constructor",
//   },
//   { payable: true, stateMutability: "payable", type: "fallback" },
//   {
//     anonymous: false,
//     inputs: [
//       { indexed: true, name: "to", type: "address" },
//       { indexed: false, name: "amount", type: "uint256" },
//     ],
//     name: "Mint",
//     type: "event",
//   },
//   { anonymous: false, inputs: [], name: "Pause", type: "event" },
//   { anonymous: false, inputs: [], name: "Unpause", type: "event" },
//   {
//     anonymous: false,
//     inputs: [{ indexed: true, name: "previousOwner", type: "address" }],
//     name: "OwnershipRenounced",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       { indexed: true, name: "previousOwner", type: "address" },
//       { indexed: true, name: "newOwner", type: "address" },
//     ],
//     name: "OwnershipTransferred",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       { indexed: true, name: "owner", type: "address" },
//       { indexed: true, name: "spender", type: "address" },
//       { indexed: false, name: "value", type: "uint256" },
//     ],
//     name: "Approval",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       { indexed: true, name: "from", type: "address" },
//       { indexed: true, name: "to", type: "address" },
//       { indexed: false, name: "value", type: "uint256" },
//     ],
//     name: "Transfer",
//     type: "event",
//   },
// ];

// 正式网
export const contractAddress_usdt =
  "0x55d398326f99059fF775485246999027B3197955";
export const contractAbi_usdt = [
  {
    inputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    constant: true,
    inputs: [],
    name: "_decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "_name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "_symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "burn",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "subtractedValue", type: "uint256" },
    ],
    name: "decreaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getOwner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "addedValue", type: "uint256" },
    ],
    name: "increaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "mint",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "sender", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
];
// EOTC充值合约
export const rechargeAddress_eotc =
  "0x64A2aE0DFC978cD6Ea4Ca891840eC0590a553947";
export const rechargeAbi_eotc = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "address", name: "ads", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "_Recharge",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "address", name: "ads", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "_transferOut",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address[]", name: "_recipients", type: "address[]" },
      { internalType: "uint256[]", name: "_values", type: "uint256[]" },
      { internalType: "address", name: "_tokenAddress", type: "address" },
    ],
    name: "AirTransfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "amount", type: "uint256" },
      { internalType: "address", name: "_token", type: "address" },
    ],
    name: "Recharge",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_sendAds", type: "address" },
      { internalType: "address", name: "_burnAds", type: "address" },
    ],
    name: "SetAds",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_fee", type: "uint256" }],
    name: "SetFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_tokenAds", type: "address" },
      { internalType: "address", name: "_approveAds", type: "address" },
    ],
    name: "SetToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getToken",
    outputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "amount", type: "uint256" },
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "address", name: "_ads", type: "address" },
    ],
    name: "transfer0",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "transferApprove",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "transferInDeposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "transferInWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "myaddress", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
      { internalType: "address", name: "_token", type: "address" },
    ],
    name: "transferOut",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "amount", type: "uint256" },
      { internalType: "address", name: "ads", type: "address" },
    ],
    name: "transferOutWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "ads", type: "address" }],
    name: "transferOutWithdrawAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address payable", name: "myaddress", type: "address" },
      { internalType: "uint256", name: "_eth", type: "uint256" },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
// LP充值地址
export const contractAddress_lp =
  "0xf1ba6eead4800546c00c520e375ed80fdfe68963";


export const contractAddress_eotc =
  "0xC1F849DC18CcC154107f615A33Db11ab06dd2EAd";
export const contractAbi_eotc = [
  {
    constant: true,
    inputs: [],
    name: "mintingFinished",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [{ name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_spender", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_from", type: "address" },
      { name: "_to", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "unpause",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_to", type: "address" },
      { name: "_amount", type: "uint256" },
    ],
    name: "mint",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "paused",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_spender", type: "address" },
      { name: "_subtractedValue", type: "uint256" },
    ],
    name: "decreaseApproval",
    outputs: [{ name: "success", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "pause",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "owner",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_to", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "mintTotal",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_spender", type: "address" },
      { name: "_addedValue", type: "uint256" },
    ],
    name: "increaseApproval",
    outputs: [{ name: "success", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { name: "_owner", type: "address" },
      { name: "_spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  { payable: true, stateMutability: "payable", type: "fallback" },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "to", type: "address" },
      { indexed: false, name: "amount", type: "uint256" },
    ],
    name: "Mint",
    type: "event",
  },
  { anonymous: false, inputs: [], name: "Pause", type: "event" },
  { anonymous: false, inputs: [], name: "Unpause", type: "event" },
  {
    anonymous: false,
    inputs: [{ indexed: true, name: "previousOwner", type: "address" }],
    name: "OwnershipRenounced",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "previousOwner", type: "address" },
      { indexed: true, name: "newOwner", type: "address" },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "owner", type: "address" },
      { indexed: true, name: "spender", type: "address" },
      { indexed: false, name: "value", type: "uint256" },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "from", type: "address" },
      { indexed: true, name: "to", type: "address" },
      { indexed: false, name: "value", type: "uint256" },
    ],
    name: "Transfer",
    type: "event",
  },
];

export const Contract_BNB = function Contract_BNB(web3c) {
  return new web3c.eth.Contract(contractAbi_BNB, contractAddress_BNB);
};

export const Contract_EOTC = function Contract_EOTC(web3c) {
  return new web3c.eth.Contract(contractAbi, contractAddress);
};
export const Webs_infura = function Webs_infura() {
  //返回infura的Web3对象
  var i_web3 = new Web3(
    new Web3.providers.HttpProvider(
      "https://rinkeby.infura.io/v3/3b5e58462f7a4daaa7ae10f44d93be36"
    )
  );
  return new i_web3.eth.Contract(contractAbi, contractAddress);
};
export const Contract_USDT = function Contract_USDT(web3c) {
  return new web3c.eth.Contract(contractAbi_usdt, contractAddress_usdt);
};
export const Contract_EOTC_token = function Contract_EOTC_token(web3c) {
  return new web3c.eth.Contract(contractAbi_eotc, contractAddress_eotc);
};
export const Contract_LP_token = function Contract_LP_token(web3c) {
  return new web3c.eth.Contract(contractAbi_eotc, contractAddress_lp);
};
export const Recharge_EOTC_token = function Recharge_EOTC_token(web3c) {
  return new web3c.eth.Contract(rechargeAbi_eotc, rechargeAddress_eotc);
};

export const Contract_Any = function Contract_Any(web3c, abi, ads) {
  return new web3c.eth.Contract(abi, ads);
};
